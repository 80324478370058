<template>
  <div class="fill-height" fluid>
    <div class="background-image-container">
      <v-select v-model="selectedCustomer" label="Customer" :items="['PIA', 'HBC']" class="mr-5"
        style="position: absolute; top: 10px; right: 10px; width: 150px; z-index: 9999;" variant="outlined"
        @update:modelValue="updateCustomer"></v-select>

      <img :src="backgroundImage" class="background-image" />
    </div>

    <v-row class="auth-row" align="end" justify="center">
      <v-col cols="10" sm="5" md="3" lg="3">
        <transition name="fade" mode="out-in">
          <v-card key="auth-card" class="elevation-12 shadowed-card" color="cornsilk" dark v-if="showCard">
            <v-row justify="center">
              <v-col cols="mx-auto">
                <v-img :src="loginLogo" />
              </v-col>
            </v-row>
            <v-card-text class="text-center">
              <v-btn class="login-btn" depressed @click="login">
                <v-icon size="22" class="login-icon">mdi-lock</v-icon>
                Authenticate
              </v-btn>
            </v-card-text>
          </v-card>
        </transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useOAuthService } from '@/services/OAuthService'
import { getStoredCustomer, setStoredCustomer } from '@/configLoader'
import { clearAllStoredData } from '@/services/utils'
import { getLoginBackgroundImage, getLoginLogoImage } from '@/assets/Branding/branding.js'
import Swal from 'sweetalert2'

export default {
  name: 'LoginPage',
  setup() {
    const { authenticate } = useOAuthService()
    const showCard = ref(true)
    const backgroundImage = ref('')
    const loginLogo = ref('')
    const selectedCustomer = ref('')
    const originalCustomerValue = ref('')

    /**
     * Load customer from IndexedDB on mount
     */
    onMounted(async () => {
      document.body.style.overflow = 'hidden'
      selectedCustomer.value = await getStoredCustomer()
      originalCustomerValue.value = selectedCustomer.value
      backgroundImage.value = (await getLoginBackgroundImage()) || ''
      loginLogo.value = (await getLoginLogoImage()) || ''
    })

    onBeforeUnmount(() => {
      document.body.style.overflow = ''
    })


    const updateCustomer = async (newCustomer) => {
      if (newCustomer === originalCustomerValue.value) return;

      // Show loading Swal
      Swal.fire({
        title: 'Switching Customer...',
        text: 'Please wait while we update your settings.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await new Promise(resolve => setTimeout(resolve, 3000));
      await clearAllStoredData();
      await setStoredCustomer(newCustomer);
      window.history.pushState({}, document.title, window.location.pathname);
      window.location.reload();
    };


    const login = () => {
      authenticate()
    }

    return { login, showCard, backgroundImage, loginLogo, originalCustomerValue, selectedCustomer, updateCustomer }
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.auth-row {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shadowed-card {
  border-radius: 16px;
  max-width: 600px;
  width: 100%;
  margin: auto;
  padding: 2rem;
  transition: transform 0.3s ease-in-out;
}

.shadowed-card:hover {
  transform: scale(1.02);
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.4), 0 2px 4px rgba(0, 0, 0, 0.2), inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}

.heading-with-shadow {
  font-size: 2rem;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  margin-bottom: 1.5rem;
}

.login-btn {
  background-color: #333;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1.75rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.login-btn:hover {
  background: linear-gradient(to right, #4caf50, #8bc34a);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.login-icon {
  margin-right: 8px;
}

.fill-height {
  position: relative;
  min-height: 100vh;
}

.background-image-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
}
</style>
<!-- LoginPage Component

This component provides a user interface for the login page of the application. It features a full-screen background image and a centered authentication card.

Users are presented with a visually appealing login screen that includes a background image and a logo. The background image is fetched dynamically when the component is mounted, ensuring that the latest branding is displayed.

The main interactive element is the authentication card, which appears with a fade transition effect. This card contains the application logo and a prominent "Authenticate" button. When users click this button, the login function is triggered, initiating the authentication process through the OAuth service.

Visual feedback is provided through the hover effects on the authentication card and the login button. The card slightly enlarges and casts a deeper shadow when hovered over, indicating interactivity. The login button changes its background gradient and slightly lifts when hovered, providing a tactile response to user actions.

The component ensures that the background image covers the entire viewport and maintains a fixed position, creating an immersive experience. The card is centered both vertically and horizontally, making it easily accessible on various screen sizes.

Overall, this component enhances the user experience by providing a clean, modern, and responsive login interface. Users can easily authenticate themselves, and the dynamic visual elements contribute to a polished and professional appearance.

-->
