import { Device } from '@capacitor/device'
import { App } from '@capacitor/app'
import { loadConfig } from '@/configLoader'
import { Preferences } from '@capacitor/preferences'
import { PushNotifications } from '@capacitor/push-notifications'
import { CapacitorCookies } from '@capacitor/core'
import Toastify from 'toastify-js'
import 'toastify-js/src/toastify.css'

import * as idb from 'idb'

/**
 * Decodes a JWT token to retrieve its payload.
 * @param {string} jwtToken - The JWT token to be decoded.
 * @returns {Object|null} - The decoded payload or null if the token is invalid.
 */
/**
 * Set a cookie with CapacitorCookies for mobile and document.cookie for web.
 * @param {string} key - The cookie name.
 * @param {string} value - The cookie value.
 * @param {number} days - Days until the cookie expires.
 */

/**
 * Get the current domain (host) for both mobile and web.
 * @returns {string} - The current domain.
 */

function getDomain() {
  return window.location.origin // This will return the full domain, e.g., 'http://example.com'
}
async function setCookie(key, value, days) {
  const isWeb = await isWebPlatform()
  const expires = days ? `; expires=${new Date(Date.now() + days * 86400000).toUTCString()}` : ''
  const domain = getDomain()

  if (isWeb) {
    // Web: Use document.cookie
    document.cookie = `${key}=${value || ''}${expires}; path=/`
  } else {
    // Mobile: Use CapacitorCookies
    await CapacitorCookies.setCookie({
      url: domain, // Dynamically use the current domain
      key: key,
      value: value,
      expires: new Date(Date.now() + days * 86400000).toUTCString(), // Setting expiration as UTC string
    })
  }
}
/**
 * Get a cookie value by key for both web and mobile platforms.
 * @param {string} key - The cookie name.
 * @returns {Promise<string|null>} - The cookie value or null if not found.
 */
async function getCookie(key) {
  const isWeb = await isWebPlatform()
  const domain = getDomain()

  if (isWeb) {
    // Web: Use document.cookie
    const nameEQ = `${key}=`
    const cookies = document.cookie.split(';')
    for (let cookie of cookies) {
      cookie = cookie.trim()
      if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length, cookie.length)
    }
    return null
  } else {
    // Mobile: Use CapacitorCookies
    const cookies = await CapacitorCookies.getCookies({ url: domain })
    return cookies[key] || null
  }
}

/**
 * Delete a cookie for both web and mobile platforms.
 * @param {string} key - The cookie name.
 */
async function deleteCookie(key) {
  const isWeb = await isWebPlatform()
  const domain = getDomain()

  if (isWeb) {
    // Web: Delete the cookie by setting its expiration date to a past date
    document.cookie = `${key}=; Max-Age=-99999999; path=/`
  } else {
    // Mobile: Use CapacitorCookies to delete the cookie
    await CapacitorCookies.deleteCookie({
      url: domain,
      key: key,
    })
  }
}

/**
 * Clear all cookies for the mobile platform or web platform.
 */
async function clearAllCookies() {
  const isWeb = await isWebPlatform()
  const domain = getDomain()

  if (isWeb) {
    // Web: Clear all cookies
    document.cookie.split(';').forEach(cookie => {
      document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
    })
  } else {
    // Mobile: Use CapacitorCookies to clear all cookies
    await CapacitorCookies.clearCookies({ url: domain })
  }
}
async function clearAllStoredData() {
  try {
    const db = await dbPromise // Use the same instance

    // Convert objectStoreNames (DOMStringList) into an array
    const storeNames = Array.from(db.objectStoreNames)

    if (storeNames.length > 0) {
      const tx = db.transaction(storeNames, 'readwrite')
      storeNames.forEach(storeName => tx.objectStore(storeName).clear())
      await tx.done
    }

    // Clear LocalStorage & SessionStorage
    localStorage.clear()
    sessionStorage.clear()

    console.log('✅ All stored data has been cleared.')
  } catch (error) {
    console.error('❌ Error clearing stored data:', error)
  }
}

function parseJwt(jwtToken) {
  if (!jwtToken) {
    console.error('Token is not valid.')
    return null
  }

  try {
    const base64Url = jwtToken.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    )
    return JSON.parse(jsonPayload)
  } catch (e) {
    console.error('Error parsing JWT:', e)
    return null
  }
}

/**
 * Checks if a decoded JWT token has expired.
 * @param {Object|null} decodedToken - The decoded JWT token.
 * @returns {boolean} - True if the token has expired, false otherwise.
 */
function isTokenExpired(decodedToken) {
  if (!decodedToken || !decodedToken.exp) {
    return true
  }
  const now = new Date().getTime() / 1000 // Current time in seconds since epoch
  return decodedToken.exp < now
}

const getAppPackageName = async () => {
  const { platform } = await Device.getInfo()
  if (platform === 'web') {
    const config = await loadConfig()
    return config?.clientId
  } else {
    const { id } = await App.getInfo()
    return id
  }
}

async function isWebPlatform() {
  const { platform } = await Device.getInfo()
  return platform === 'web'
}

async function getStoredItem(key) {
  if (await isWebPlatform()) {
    const item = sessionStorage.getItem(key)
    return item ? JSON.parse(item) : null
  } else {
    const { value } = await Preferences.get({ key })
    return value ? JSON.parse(value) : null
  }
}

async function setStoredItem(key, value) {
  try {
    if (await isWebPlatform()) {
      sessionStorage.setItem(key, JSON.stringify(value))
    } else {
      await Preferences.set({ key, value: JSON.stringify(value) })
    }
  } catch (error) {
    console.error('Error setting stored item:', error)
  }
}

async function removeAllStoredItems() {
  if (await isWebPlatform()) {
    sessionStorage.clear()
    localStorage.clear()
  } else {
    await Preferences.clear()
  }
}

async function removeStoredItem(key) {
  // Remove from sessionStorage or Preferences based on platform
  if (await isWebPlatform()) {
    sessionStorage.removeItem(key)
    localStorage.removeItem(key) // Remove from localStorage as well on the web
  } else {
    await Preferences.remove({ key })
  }

  // Remove the specified key from the 'blobs' store in IndexedDB
  const db = await dbPromise
  if (db.objectStoreNames.contains('blobs')) {
    const tx = db.transaction('blobs', 'readwrite')
    await tx.objectStore('blobs').delete(key)
    await tx.done
    console.log(`Deleted ${key} from 'blobs' store in IndexedDB.`)
  }
}

// Increment the version number if you need to force an upgrade
const dbPromise = idb.openDB('myAppDatabase', 2, {
  // Change version to 2 or higher if needed
  upgrade(db) {
    // Create 'reservations' store if it doesn't exist
    if (!db.objectStoreNames.contains('reservations')) {
      db.createObjectStore('reservations', { keyPath: 'id' })
    }
    // Create 'appointments' store if it doesn't exist
    if (!db.objectStoreNames.contains('appointments')) {
      db.createObjectStore('appointments', { keyPath: 'id' })
    }
    // Create 'blobs' store if it doesn't exist
    if (!db.objectStoreNames.contains('blobs')) {
      db.createObjectStore('blobs')
    }
    if (!db.objectStoreNames.contains('incidents')) {
      db.createObjectStore('incidents')
    }
    if (!db.objectStoreNames.contains('users')) {
      db.createObjectStore('users', { keyPath: 'id' })
    }
    if (!db.objectStoreNames.contains('customer')) {
      db.createObjectStore('customer', { keyPath: 'id' })
    }
  },
})
async function storeBlob(key, blob) {
  const db = await dbPromise
  const tx = db.transaction('blobs', 'readwrite')
  tx.objectStore('blobs').put(blob, key)
  await tx.complete
}

async function retrieveBlob(key) {
  const db = await dbPromise
  return db.transaction('blobs').objectStore('blobs').get(key)
}

async function checkNotificationPermission() {
  const { platform } = await Device.getInfo()

  // Handle Web Notifications
  if (platform === 'web') {
    if (Notification.permission === 'granted') {
      console.log('Web notifications are already granted.')
      return 'granted'
    } else if (Notification.permission === 'denied') {
      console.log('Web notifications are denied.')
      return 'denied'
    } else if (Notification.permission === 'default') {
      console.log('Web notifications permission is not set.')
      return 'default'
    }
  }

  // Handle Mobile (Android/iOS) Notifications with Capacitor
  else {
    const currentStatus = await PushNotifications.checkPermissions()
    if (currentStatus.receive === 'granted') {
      console.log('Push notifications are already granted on mobile.')
      return 'granted'
    } else if (currentStatus.receive === 'denied') {
      console.log('Push notifications are denied on mobile.')
      return 'denied'
    } else {
      console.log('Push notifications permission is not set on mobile.')
      return 'default'
    }
  }
}

async function requestNotificationPermission() {
  const { platform } = await Device.getInfo()

  // Handle Web Notifications
  if (platform === 'web') {
    if (Notification.permission === 'granted') {
      console.log('Notifications are already granted.')
      // showToast({
      //   text: 'Web notifications are already enabled.',
      //   type: 'success',
      // })
      return 'granted'
    } else if (Notification.permission === 'denied') {
      console.log('Notifications are denied by the user.')
      showToast({
        text: 'Web notifications permission was denied.',
        type: 'error',
      })
      return 'denied'
    } else if (Notification.permission === 'default') {
      // Request permission if it hasn't been granted or denied
      const permission = await Notification.requestPermission()
      if (permission === 'granted') {
        console.log('Web notifications permission granted.')
        showToast({
          text: 'Web notifications permission granted.',
          type: 'success',
        })
      } else {
        console.log('Web notifications permission denied.')
        showToast({
          text: 'Web notifications permission denied.',
          type: 'error',
        })
      }
      return permission
    }
  }

  // Handle Mobile (Android/iOS) Notifications with Capacitor
  else {
    // Check the current permissions
    const currentStatus = await PushNotifications.checkPermissions()
    if (currentStatus.receive === 'granted') {
      console.log('Push notifications are already enabled on mobile.')
      // showToast({
      //   text: 'Push notifications are already enabled on mobile.',
      //   type: 'success',
      // })
      return 'granted'
    } else {
      // Request permission
      const result = await PushNotifications.requestPermissions()
      if (result.receive === 'granted') {
        console.log('Push notifications permission granted on mobile.')
        showToast({
          text: 'Push notifications permission granted.',
          type: 'success',
        })
        await PushNotifications.register() // Register for push notifications
        return 'granted'
      } else {
        console.log('Push notifications permission denied on mobile.')
        showToast({
          text: 'Push notifications permission denied.',
          type: 'error',
        })
        return 'denied'
      }
    }
  }
}

const showToast = ({ text, duration = 3000, type = 'default' }) => {
  // Define styles for each type
  const styles = {
    default: {
      background: '#000', // Dark background
      color: '#fff', // White text color
      opacity: '0.9', // Slight transparency
      transition: 'opacity 0.5s ease, transform 0.5s ease', // Smooth transitions
      borderRadius: '12px', // Rounded corners
      padding: '16px 24px', // Generous padding
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)', // Soft shadow for depth
      fontFamily: '"Poppins", sans-serif', // Modern font family
      fontSize: '16px', // Slightly larger font
      textAlign: 'center', // Centered text
      maxWidth: '300px', // Restrict width for a neat look
    },
    success: {
      background: '#4caf50', // Success green
      color: '#fff',
      opacity: '0.9',
      transition: 'opacity 0.5s ease, transform 0.5s ease',
      borderRadius: '12px',
      padding: '16px 24px',
      boxShadow: '0 4px 12px rgba(0, 150, 0, 0.3)', // Greenish shadow for success
      fontFamily: '"Poppins", sans-serif',
      fontSize: '16px',
      textAlign: 'center',
      maxWidth: '300px',
    },
    error: {
      background: '#f44336', // Error red
      color: '#fff',
      opacity: '0.9',
      transition: 'opacity 0.5s ease, transform 0.5s ease',
      borderRadius: '12px',
      padding: '16px 24px',
      boxShadow: '0 4px 12px rgba(255, 0, 0, 0.3)', // Reddish shadow for error
      fontFamily: '"Poppins", sans-serif',
      fontSize: '16px',
      textAlign: 'center',
      maxWidth: '300px',
    },
  }

  // Clear the previous toast if any, and show a new one
  Toastify.reposition() // Ensures no overlap with previous toasts
  Toastify({
    text: text,
    duration: duration,
    newWindow: true,
    close: false,
    gravity: 'bottom', // Set toast to appear at the bottom
    position: 'center', // Center horizontally
    stopOnFocus: true, // Prevents dismissing when hovered
    style: {
      'margin-bottom': '8rem', // Adjust the bottom margin for better visibility
      ...styles[type], // Apply the appropriate style based on the type parameter
    },
  }).showToast()
}
export {
  getAppPackageName,
  parseJwt,
  isTokenExpired,
  isWebPlatform,
  getStoredItem,
  showToast,
  setStoredItem,
  removeStoredItem,
  removeAllStoredItems,
  storeBlob,
  retrieveBlob,
  dbPromise,
  requestNotificationPermission,
  checkNotificationPermission,
  setCookie,
  getCookie,
  clearAllStoredData,
  deleteCookie,
  clearAllCookies,
}
